var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',{staticClass:"management-report-card-box"},[_c('el-form',[_c('div',{staticClass:"flex flex-row justify-between items-center mb-2"},[_c('h2',{staticClass:"text-base font-semibold mb-2"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"flex items-center"},[_c('el-switch',{attrs:{"active-color":"#48c78e","inactive-color":"#D1D5DB"},on:{"change":_vm.onToggleChange},model:{value:(_vm.localToggleValue),callback:function ($$v) {_vm.localToggleValue=$$v},expression:"localToggleValue"}})],1)]),(_vm.toggleValue)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.$waiting.is('loading-placedSecurities')),expression:"$waiting.is('loading-placedSecurities')"}],staticClass:"flex flex-col"},[_c('el-table',{attrs:{"data":_vm.data.longTermLiabilitiesTableRows}},[_c('el-table-column',{attrs:{"label":"Text","width":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"disabled":_vm.inputDisabled},model:{value:(row.description),callback:function ($$v) {_vm.$set(row, "description", $$v)},expression:"row.description"}})]}}],null,false,1915320657)}),_c('el-table-column',{attrs:{"label":"Nuvarande år"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"disabled":_vm.inputDisabled},model:{value:(row.currentYear),callback:function ($$v) {_vm.$set(row, "currentYear", $$v)},expression:"row.currentYear"}})]}}],null,false,2541053905)}),_c('el-table-column',{attrs:{"label":"Föregående år"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"disabled":_vm.inputDisabled},model:{value:(row.previousYear),callback:function ($$v) {_vm.$set(row, "previousYear", $$v)},expression:"row.previousYear"}})]}}],null,false,779979657)}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
var store = ref.store;
var row = ref.row;
return [(store.states.data.length !== 1)?[_c('el-button',{attrs:{"plain":"","size":"mini","disabled":_vm.inputDisabled},on:{"click":function($event){return _vm.deleteRowTable(row)}}},[_c('i',{staticClass:"fa-solid fa-minus"})])]:_vm._e(),($index === store.states.data.length - 1)?[_c('el-button',{attrs:{"plain":"","size":"mini","disabled":_vm.inputDisabled},on:{"click":_vm.addRowTable}},[_c('i',{staticClass:"fa-solid fa-plus"})])]:_vm._e()]}}],null,false,283176030)})],1),_c('el-input',{staticClass:"break-normal custom-textarea p-2",attrs:{"type":"textarea","disabled":_vm.inputDisabled},model:{value:(_vm.localInputText),callback:function ($$v) {_vm.localInputText=$$v},expression:"localInputText"}})],1):_vm._e()]),_c('CardEditButtons',{attrs:{"toggle-value":_vm.toggleValue,"show-edit-button":_vm.showEditButton,"toggle-edit":_vm.toggleEdit,"close-modal":_vm.closeModal,"save-data":_vm.saveData}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }